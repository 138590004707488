<template>
  <div>
    <v-container fluid>
      <div class="main">


        <v-row v-if="isLoading">
          <v-col v-for="item in 4" :key="item" cols="12" lg="3">
            <v-card class="rounded-lg shadow">
              <v-card-text>
                <v-skeleton-loader type="list-item-avatar-three-line"></v-skeleton-loader>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row v-else>

          <v-col cols="12" lg="3">
            <v-card class="rounded-lg shadow">
              <v-card-text>
                <v-row align="center" justify="center">
                  <v-col cols="3">
                    <v-avatar color="gifty" size="45">
                      <v-icon color="white">mdi-account-group-outline</v-icon>
                    </v-avatar>
                  </v-col>
                  <v-col cols="9">
                    <div class="font-weight-black title pb-1 grey--text text--darken-3">
                      {{ total }}
                    </div>
                    Total des demandes
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" lg="3">
            <v-card class="rounded-lg shadow">
              <v-card-text>
                <v-row align="center" justify="center">
                  <v-col cols="3">
                    <v-avatar color="primary" size="45">
                      <v-icon color="white">mdi-clock-outline</v-icon>
                    </v-avatar>
                  </v-col>
                  <v-col cols="9">
                    <div class="font-weight-black title pb-1 grey--text text--darken-3">
                      {{ pending }}
                    </div>
                    Total En attente
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" lg="3">
            <v-card class="rounded-lg shadow">
              <v-card-text>
                <v-row align="center" justify="center">
                  <v-col cols="3">
                    <v-avatar color="success" size="45">
                      <v-icon color="white">mdi-check-circle-outline</v-icon>
                    </v-avatar>
                  </v-col>
                  <v-col cols="9">
                    <div class="font-weight-black title pb-1 grey--text text--darken-3">
                      {{ verified }}
                    </div>
                    Total Vérifié
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" lg="3">
            <v-card class="rounded-lg shadow">
              <v-card-text>
                <v-row align="center" justify="center">
                  <v-col cols="3">
                    <v-avatar color="error" size="45">
                      <v-icon color="white">mdi-alert-circle-outline</v-icon>
                    </v-avatar>
                  </v-col>
                  <v-col cols="9">
                    <div class="font-weight-black title pb-1 grey--text text--darken-3">
                      {{ failed }}
                    </div>
                    Total Échoué
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

        </v-row>


        <v-row>
          <v-col cols="12">

            <v-card class="rounded-lg shadow">
              <v-overlay :value="overlay" absolute color="white" opacity="0.5">
                <v-progress-circular
                    color="primary"
                    indeterminate
                    size="50"
                ></v-progress-circular>
              </v-overlay>

              <v-card-title>
                <div>
                  <v-text-field
                      v-model="keyword"
                      append-icon="mdi-magnify"
                      clearable
                      dense
                      filled
                      hide-details
                      placeholder="Rechercher..."
                      rounded
                      single-line

                  />
                </div>
                <v-spacer/>

                <v-btn color="grey"
                       text
                       @click="getVerifications">
                  <v-icon left>mdi-refresh</v-icon>
                  Mettre à jour
                </v-btn>

              </v-card-title>

              <v-divider/>

              <v-card-text v-if="isLoading">
                <v-skeleton-loader type="table"/>
              </v-card-text>

              <div v-else>
                <v-card-text class="pa-0">
                  <v-simple-table v-if="users.length">
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th>
                          <v-icon small>mdi-delete-alert-outline</v-icon>
                        </th>
                        <th class="text-no-wrap">Utilisateur</th>
                        <th>Téléphone</th>
                        <th class="text-no-wrap">Recto ID</th>
                        <th class="text-no-wrap">Verso ID</th>
                        <th class="text-no-wrap">Selfie ID</th>
                        <th class="text-no-wrap">Passport</th>
                        <th>Statut</th>
                        <th class="text-no-wrap">Date de demande</th>
                        <th class="text-no-wrap">Vérifier le</th>
                        <th>Actions</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(user ,index) in users"
                          :key="index"
                      >

                        <td>
                          <p class="red--text mb-0 font-weight-medium"
                             v-if="user.user && user.user.deleted_at">
                            <v-icon small color="red">mdi-delete-forever</v-icon>
                          </p>
                        </td>

                        <td>
                          <Avatar :user="user.user"/>
                        </td>

                        <td>
                          {{ user.user.phone }}
                        </td>

                        <td>
                          <v-avatar class="cursor-zomm-in" rounded size="35" @click="showLightbox(0,user)">
                            <v-img class="grey lighten-4" :src="getImageLink(user.id_card_image_front)"></v-img>
                          </v-avatar>
                        </td>

                        <td>
                          <v-avatar class="cursor-zomm-in" rounded size="35" @click="showLightbox(1,user)">
                            <v-img class="grey lighten-4" :src="getImageLink(user.id_card_image_back)"></v-img>
                          </v-avatar>
                        </td>

                        <td>
                          <v-avatar class="cursor-zomm-in" rounded size="35" @click="showLightbox(2,user)">
                            <v-img class="grey lighten-4" :src="getImageLink(user.selfie_image)"></v-img>
                          </v-avatar>
                        </td>

                        <td>
                          <v-avatar class="cursor-zomm-in" rounded size="35" @click="showLightbox(3,user)">
                            <v-img class="grey lighten-4" :src="getImageLink(user.passport_image)"></v-img>
                          </v-avatar>
                        </td>

                        <td>
                          <v-chip small text-color="primary"
                                  class="bg-subtle-warning font-weight-medium"
                                  v-if="user.user.verification_status === 'pending'">
                            <v-icon left small color="primary">mdi-clock-outline</v-icon>
                            En attente
                          </v-chip>

                          <v-chip small text-color="success"
                                  class="bg-subtle-success font-weight-medium"
                                  v-if="user.user.verification_status === 'verified'">
                            <v-icon left small color="success">mdi-check-circle-outline</v-icon>
                            Vérifié
                          </v-chip>

                          <v-chip small text-color="primary"
                                  class="bg-subtle-warning font-weight-medium"
                                  v-if="user.user.verification_status === 'unverified'">
                            <v-icon left small color="primary">mdi-alert-circle-outline</v-icon>
                            Non vérifié
                          </v-chip>

                          <v-chip small text-color="error"
                                  class="bg-subtle-error font-weight-medium"
                                  v-if="user.user.verification_status === 'failed'">
                            <v-icon left small color="error">mdi-close-circle-outline</v-icon>
                            Échoué
                          </v-chip>

                        </td>

                        <td class="text-no-wrap">
                          {{ user.created_at }}
                        </td>

                        <td class="text-no-wrap">
                          <template v-if="user.user.verification_status === 'verified'">
                            {{ user.updated_at }}
                          </template>
                          <div v-else>
                            -
                          </div>
                        </td>

                        <td>
                          <div class="d-flex align-center">
                            <v-tooltip top color="gifty">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs"
                                       :color="(user.remark) ? 'gifty' : 'grey'"
                                       v-on="on"
                                       @click="[dialog2 = true, user_verification_detail = user]">
                                  <v-icon v-if="user.remark">mdi-comment</v-icon>
                                  <v-icon v-else>mdi-comment-outline</v-icon>
                                </v-btn>
                              </template>
                              <span>Remarque</span>
                            </v-tooltip>

                            <v-tooltip top color="gifty" v-if="can('admin')">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on" @click="remove(user.id)">
                                  <v-icon>mdi-delete-outline</v-icon>
                                </v-btn>
                              </template>
                              <span>Supprimer</span>
                            </v-tooltip>

                            <v-tooltip top color="gifty">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    icon
                                    :disabled="user.user.deleted_at || (user.user.verification_status !== 'unverified' && user.user.verification_status !== 'pending')"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="changeVerificationStatus(user)"
                                >
                                  <v-icon>mdi-sync</v-icon>
                                </v-btn>
                              </template>
                              <span>Modifier le statut de vérification</span>
                            </v-tooltip>
                          </div>
                        </td>

                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>

                  <div v-else>
                    <NoResults @reload="getVerifications"/>
                  </div>
                </v-card-text>

                <v-divider v-if="total > 10"/>

                <v-card-actions v-if="total > 10">

                  <div :style="{width: '120px'}">
                    <v-select v-model="perPage"
                              :items="[10,20,30,50,100]"
                              dense
                              hide-details
                              label="Ligne par page"
                              outlined></v-select>
                  </div>

                  <v-spacer/>

                  <v-pagination v-model="pagination.current"
                                :length="pagination.total"
                                circle
                                total-visible="8"
                                @input="getVerifications"></v-pagination>

                </v-card-actions>

              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>


      <v-dialog v-model="dialog2" width="500">
        <v-card>
          <v-card-title>
            Remarque
            <v-spacer></v-spacer>
            <v-icon @click="dialog2 = false">mdi-close</v-icon>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="pa-8">

            <v-alert v-if="!user_verification_detail.remark" type="info" text>
              Aucune remarque disponible
            </v-alert>

            {{ user_verification_detail.remark }}

          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialog" width="500">
        <v-card>
          <v-card-title>
            Modifier le Statut de Vérification
            <v-spacer></v-spacer>
            <v-icon @click="dialog = false">mdi-close</v-icon>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="pa-8">
            <v-form ref="form">

              <v-radio-group v-model="verification_status"
                             :error-messages="errors.verification_status"
                             label="Veuillez sélectionner un statut de vérification *">
                <v-radio v-for="status in statuses"
                         :key="status.value"
                         :value="status.value"
                         :label="status.text"
                         class="radio"
                         :color="status.value === 'verified' ? 'success' : 'red'"
                         :class="verification_status === status.value? status.class : ''"
                >
                  <template v-slot:label>
                    <div class="d-flex align-center justify-space-between">
                      <div>{{ status.text }}</div>
                      <div class="ml-2">
                        <v-icon :color="status.value === 'verified' ? 'success' : 'red'">
                          {{ status.value === 'verified' ? 'mdi-account-check-outline' : 'mdi-account-cancel-outline' }}
                        </v-icon>
                      </div>
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>


              <v-text-field outlined
                            :error-messages="errors.name"
                            dense
                            v-model="name"
                            label="Nom d'utilisateur *"
              ></v-text-field>

              <v-textarea outlined
                          :error-messages="errors.remark"
                          dense
                          hide-details
                          v-model="remark"
                          label="Remarques"
                          placeholder="Ajoutez des remarques ici"
                          auto-grow
              ></v-textarea>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary"
                   :disabled="!verification_status"
                   depressed
                   @click="saveStatus"
                   :loading="btnLoading">
              <v-icon left>mdi-content-save</v-icon>
              Enregistrer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>


      <vue-easy-lightbox escDisabled
                         moveDisabled
                         :visible="visible"
                         :imgs="image"
                         :index="index"
                         @hide="visible = false">
      </vue-easy-lightbox>

    </v-container>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import NoResults from "@/components/NoResults";
import Avatar from "@/components/Avatar";

export default {
  components: {Avatar, NoResults},
  data() {
    return {
      keyword: '',
      id: '',
      dialog2: false,
      dialog: false,
      overlay: false,
      isLoading: false,
      users: [],

      filter: {},
      total: 0,
      perPage: 10,
      pagination: {
        current: 1,
        total: 0
      },

      pending: 0,
      verified: 0,
      failed: 0,

      btnLoading: false,
      errors: {},
      user: {},
      user_verification_detail: {},
      name: '',
      remark: '',
      verification_status: '',
      statuses: [
        {text: 'Vérifié', value: 'verified', class: "success-radio"},
        {text: 'Échoué', value: 'failed', class: "red-radio"}
      ],

      //lightbox
      fileUrl: process.env.VUE_APP_FILE_URL,
      image: [],
      visible: false,
      index: 0
    };
  },
  methods: {
    changeVerificationStatus(user) {
      this.user = user
      this.name = user.user.name
      this.verification_status = ""
      this.remark = ""
      this.errors = {}
      this.dialog = true
    },
    showLightbox(index, user) {
      this.image = [
        {src: this.fileUrl + user.id_card_image_front},
        {src: this.fileUrl + user.id_card_image_back},
        {src: this.fileUrl + user.selfie_image},
        {src: this.fileUrl + user.passport_image},
      ];
      this.index = index;
      this.visible = true;
    },
    getVerifications() {
      this.isLoading = true
      HTTP.get('/v1/users-verification?page=' + this.pagination.current, {
        params: {
          perPage: this.perPage,
          verification_status: this.filter.verification_status,
          keyword: this.keyword
        }
      }).then(res => {
        this.pagination.current = res.data.data.current_page;
        this.pagination.total = res.data.data.last_page;
        this.total = res.data.data.total;

        this.users = res.data.data.data

        this.pending = res.data.counts.pending
        this.verified = res.data.counts.verified
        this.failed = res.data.counts.failed

        if (this.$store.state.user && this.$store.state.user.user.account.pending_user_verifications) {
          this.$store.state.user.user.account.pending_user_verifications = this.pending
        }

        this.isLoading = false
      }).catch(err => {
        this.isLoading = false
        console.log(err)
      })
    },
    remove(id) {
      this.id = id;
      this.$confirm_dialog = true;
    },
    deleteItem() {
      this.overlay = true;
      HTTP.post("/v1/users-verification/delete", {
        id: this.id,
      }).then(() => {
        this.overlay = false;
        let i = this.users.findIndex((el) => el.id === this.id);
        this.$delete(this.users, i);
        this.id = null;
        this.$successMessage = "Cet élément a été supprimé avec succès.";
      }).catch((err) => {
        this.overlay = false;
        console.log(err);
      });
    },
    saveStatus() {
      this.btnLoading = true;
      HTTP.post("/v1/users-verification/change-verification-status", {
        user_id: this.user.user_id,
        verification_status: this.verification_status,
        remark: this.remark,
        name: this.name,
      }).then(() => {
        this.dialog = false;
        this.btnLoading = false;
        this.getVerifications()
        this.$successMessage = "Cet élément a été enregistré avec succès.";
      }).catch((err) => {
        this.errors = err.response.data.errors
        this.btnLoading = false;
        console.log(err);
      });
    },
  },
  created() {
    this.getVerifications();
  },
  watch: {
    $is_confirm: function (el) {
      if (el) {
        this.deleteItem();
        this.$is_confirm = false;
      }
    },
    perPage() {
      this.pagination.current = 1
      this.getVerifications();
    },
    keyword() {
      this.getVerifications();
    },
  }
};
</script>

<style scoped>
.cursor-zomm-in {
  cursor: zoom-in;
}

.bg-subtle-warning {
  background: rgba(255, 171, 2, 0.15) !important;
}

.bg-subtle-success {
  background: rgba(121, 215, 15, 0.15) !important;
}

.bg-subtle-error {
  background: rgba(255, 36, 66, 0.15) !important;
}

.radio {
  border: solid 2px #d5d5d5;
  border-radius: 4px;
  padding: 10px;
}

.radio {
  border: solid 2px #d5d5d5;
  border-radius: 4px;
  padding: 10px;
}

.success-radio {
  border-color: #19e912;
}

.red-radio {
  border-color: #ff5252;
}
</style>

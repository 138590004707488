<template>
  <div>
    <v-container fluid>
      <div class="main">

        <v-overlay :value="isLoading || showLoading"
                   color="white"
                   opacity="0.5">
          <v-progress-circular
              color="primary"
              indeterminate
              size="50"
          ></v-progress-circular>
        </v-overlay>

        <v-row align="center" justify="center">
          <v-col cols="12" lg="10">

            <!-- General Errors -->
            <v-alert type="error" class="mb-3" text border="left" v-if="groupedErrors.general.length">
              <h4 class="font-weight-bold">Erreurs générales</h4>
              <ul>
                <li v-for="(error, index) in groupedErrors.general" :key="index">{{ error }}</li>
              </ul>
            </v-alert>

            <!-- Investigations Errors -->
            <v-alert type="error" class="mb-3" text border="left"
                     v-if="Object.keys(groupedErrors.investigations).length">
              <h4 class="font-weight-bold">Erreurs des enquêtes</h4>
              <div v-for="(investigation, index) in groupedErrors.investigations" :key="'investigation-' + index">
                <h5 class="font-weight-bold">Investigation {{ index + 1 }}</h5>
                <ul>
                  <li v-for="(error, i) in investigation.errors" :key="'error-' + i">{{ error }}</li>
                </ul>

                <!-- Tasks Errors -->
                <div v-if="Object.keys(investigation.tasks).length">
                  <h6 class="font-weight-bold">Tâches</h6>
                  <div v-for="(taskErrors, taskIndex) in investigation.tasks" :key="'task-' + taskIndex">
                    <h6 class="font-weight-bold">Tâche {{ parseInt(taskIndex) + 1 }}</h6>
                    <ul>
                      <li v-for="(error, ti) in taskErrors" :key="'task-error-' + ti">{{ error }}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </v-alert>

            <v-card class="shadow rounded-lg sticky-card">

              <v-card-title>
                {{ campaign_id ? "Modifier un campagne" : "Nouvelle campagne" }}
                <v-spacer/>
                <v-btn icon color="gifty" to="/opus/campaigns" exact>
                  <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-subtitle>
                <v-alert color="gifty" text  dense>
                  Tous les champs indiqués avec une étoile (*) sont obligatoires.
                </v-alert>
              </v-card-subtitle>

              <v-divider/>

              <v-card-text class="pa-6">

                <v-stepper v-model="tab" flat outlined non-linear alt-labels>
                  <v-stepper-header>

                    <v-stepper-step editable step="1" color="gifty">
                     <span class="text-no-wrap">
                         Info & Plannification
                     </span>
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step editable step="2" color="gifty">
                      Enquêtes
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step step="3" editable color="gifty">
                      Tâches
                    </v-stepper-step>

                  </v-stepper-header>
                </v-stepper>

              </v-card-text>
            </v-card>

            <v-card class="shadow rounded-lg mt-3" v-if="tab === '1'">
              <v-card-text class="px-4 px-lg-15 py-10">
                <InfoPlanning :campaign="campaign"/>
              </v-card-text>
            </v-card>

            <Investigations v-if="tab === '2'" :campaign="campaign"/>

            <Tasks v-if="tab === '3'"
                   :campaign="campaign"
                   :answer_types="answer_types"
                   :question_types="question_types"/>

            <div class="d-flex align-center justify-space-between mt-3">

              <v-btn text class="rounded-lg"
                     v-if="tab === '2'"
                     @click="[tab = '1',$vuetify.goTo(0)]">
                <v-icon left>mdi-arrow-left</v-icon>
                Précédent
              </v-btn>

              <v-btn text class="rounded-lg"
                     v-if="tab === '3'"
                     @click="[tab = '2',$vuetify.goTo(0)]">
                <v-icon left>mdi-arrow-left</v-icon>
                Précédent
              </v-btn>

              <v-spacer/>

              <v-btn color="gifty" depressed dark class="rounded-lg"
                     v-if="tab === '1'"
                     @click="[tab = '2',$vuetify.goTo(0)]">
                Suivant
                <v-icon right>mdi-arrow-right</v-icon>
              </v-btn>

              <v-btn color="gifty" depressed dark class="rounded-lg"
                     v-if="tab === '2'"
                     @click="[tab = '3',$vuetify.goTo(0)]">
                Suivant
                <v-icon right>mdi-arrow-right</v-icon>
              </v-btn>

              <v-btn color="gifty" depressed dark class="rounded-lg"
                     :disabled="isLoading"
                     v-if="tab === '3'"
                     @click="saveCampaign">
                Sauvgarder
                <v-icon right>mdi-content-save</v-icon>
              </v-btn>

            </div>

          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
import InfoPlanning from "@/views/opus/campaigns/create/components/InfoPlanning";
import Investigations from "@/views/opus/campaigns/create/components/Investigations";
import Tasks from "@/views/opus/campaigns/create/components/Tasks";
import {HTTP} from "@/http-common";
import moment from 'moment'

export default {
  components: {Tasks, Investigations, InfoPlanning},
  data() {
    return {
      tab: "1",
      campaign_id: null,
      campaign: {
        name: {
          fr: '',
          ar: '',
        },
        start_date: '',
        end_date: '',
        description: {
          fr: '',
          ar: '',
        },
        images: [],
        is_active: true,
        investigations: [
          {
            name: {
              fr: '',
              ar: '',
            },
            start_date: '',
            end_date: '',
            description: {
              fr: '',
              ar: '',
            },
            images: [],
            winning_points: '',
            for_all_users: false,
            user_ids: [],
            is_active: true,
            tasks: [
              {
                question_type_key: "text",
                answer_type_key: "checkbox",
                question: {},
                number_of_photos: "",
                file: "",
                options: [
                  {option: {}},
                  {option: {}},
                ],
              }
            ]
          }
        ]
      },
      answer_types: [],
      question_types: [],

      errors: {},
      isLoading: false,
      showLoading: false,
    }
  },
  methods: {
    getTypes() {
      this.isLoading = true
      HTTP.get('/v1/opus/campaigns/types').then((res) => {
        this.answer_types = res.data.data.answer_types
        this.question_types = res.data.data.question_types
        this.isLoading = false
      }).catch(err => {
        this.isLoading = false
        console.log(err)
      })
    },
    saveCampaign() {
      this.isLoading = true
      this.errors = {}
      let url = !this.campaign_id ? "/v1/opus/campaigns/store" : "/v1/opus/campaigns/" + this.campaign_id + "/update"
      HTTP.post(url, this.campaign).then(() => {
        this.isLoading = false
        this.$successMessage = "La campagne a été enregistrée avec succès."
        this.$router.push("/opus/campaigns")
      }).catch(err => {
        this.isLoading = false
        this.errors = err.response.data.errors
        this.$errorMessage = "Une erreur s'est produite veuillez corriger."
        this.$vuetify.goTo(0)
        console.log(err)
      })
    },
    showCampaign() {
      this.showLoading = true
      HTTP.get('/v1/opus/campaigns/' + this.campaign_id + '/show').then((res) => {
        this.showLoading = false
        if (res && res.data.data) {

          this.campaign = res.data.data

          this.campaign.investigations.forEach(investigation => {
            if (investigation.start_date) {
              investigation.start_date = moment(investigation.start_date).format('YYYY-MM-DD');
            }
            if (investigation.end_date) {
              investigation.end_date = moment(investigation.end_date).format('YYYY-MM-DD');
            }
          })

        } else {
          this.$router.push('/404')
        }
      }).catch(err => {
        this.showLoading = false
        console.log(err)
      })
    },
  },
  created() {
    this.getTypes()
    if (this.$route.name === "edit.opusCampaign") {
      this.campaign_id = parseInt(this.$route.params.id)
      this.showCampaign()
    }
  },
  computed: {
    groupedErrors() {
      const general = [];
      const investigations = {};

      for (const key in this.errors) {
        if (!key.includes(".")) {
          // General errors
          general.push(...this.errors[key]);
        } else if (key.startsWith("investigations")) {
          const parts = key.split(".");
          const investigationIndex = parts[1]; // e.g., 0 or 1
          const subkey = parts.slice(2).join("."); // e.g., name.ar or tasks.0.question.ar

          if (!investigations[investigationIndex]) {
            investigations[investigationIndex] = {errors: [], tasks: {}};
          }

          if (subkey.startsWith("tasks")) {
            // Handle task-specific errors
            const taskIndex = subkey.split(".")[1]; // e.g., 0 for tasks.0.question.ar
            const taskKey = subkey.split(".").slice(2).join("."); // e.g., question.ar

            if (!investigations[investigationIndex].tasks[taskIndex]) {
              investigations[investigationIndex].tasks[taskIndex] = [];
            }

            investigations[investigationIndex].tasks[taskIndex].push(...this.errors[key]);
          } else {
            // Add investigation-level errors
            investigations[investigationIndex].errors.push(...this.errors[key]);
          }
        }
      }

      return {general, investigations};
    },
  }
}
</script>

<style scoped>
.sticky-card {
  position: sticky;
  top: -50px;
  z-index: 1
}
</style>
<template>
  <div>

    <v-alert type="info" text class="rounded-lg">
      <strong> Informations générales sur la campagne : </strong>
      Vous pouvez gérer les enquêtes, définir des dates de début et de fin, et personnaliser les détails de la campagne.
    </v-alert>


    <v-row>

      <v-col cols="6">
        <p class="font-weight-medium mb-2 required">
          Titre
        </p>
        <v-text-field v-model="campaign.name.fr"
                      outlined
                      hide-details="auto"
                      dense
                      placeholder="Titre de la campagne"/>
      </v-col>

      <v-col cols="6">
        <p class="font-weight-medium mb-2 required ar">
          العنوان
        </p>
        <v-text-field v-model="campaign.name.ar"
                      outlined
                      dense
                      class="ar"
                      hide-details="auto"
                      placeholder="عنوان الحملة"/>
      </v-col>

      <v-col cols="6">
        <p class="font-weight-medium mb-2 required">Date de début</p>
        <v-menu
            v-model="startDateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="campaign.start_date"
                v-bind="attrs"
                v-on="on"
                prepend-inner-icon="mdi-calendar"
                readonly
                outlined
                dense
                placeholder="Date de début"
                hide-details="auto"
            />
          </template>
          <v-date-picker color="gifty"
                         v-model="campaign.start_date"
                         @input="startDateMenu = false"
          />
        </v-menu>
      </v-col>

      <v-col cols="6">
        <p class="font-weight-medium mb-2 required">Date de fin</p>
        <v-menu
            v-model="endDateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="campaign.end_date"
                v-bind="attrs"
                v-on="on"
                readonly
                prepend-inner-icon="mdi-calendar"
                outlined
                dense
                placeholder="Date de fin"
                hide-details="auto"
            />
          </template>
          <v-date-picker color="gifty"
                         v-model="campaign.end_date"
                         @input="endDateMenu = false"
          />
        </v-menu>
      </v-col>

      <v-col cols="12">
        <p class="font-weight-medium mb-2 required">
          Description
        </p>
        <v-textarea v-model="campaign.description.fr"
                    rows="2"
                    hide-details="auto"
                    outlined
                    dense
                    placeholder="Description de la campagne"/>
      </v-col>

      <v-col cols="12">
        <p class="font-weight-medium mb-2 required ar">
          الوصف
        </p>
        <v-textarea v-model="campaign.description.ar"
                    rows="2"
                    class="ar"
                    hide-details="auto"
                    outlined
                    dense
                    placeholder="وصف الحملة"
        />
      </v-col>

      <v-col cols="12" class="mb-0 pb-0">
        <p class="font-weight-medium mb-2">
          Photos (Sélection multiple)
        </p>
        <v-file-input outlined
                      ref="image"
                      :clearable="false"
                      :disabled="isLoading"
                      :loading="isLoading"
                      accept="image/png,image/jpeg"
                      @change="uploadPhotos($event)"
                      multiple
                      show-size
                      chips
                      small-chips
                      counter dense
                      placeholder="Photos de la campagne(png.jpeg,svg)"/>

        <div v-if="campaign.images && campaign.images.length">
          <div v-for="(image,i) in campaign.images" :key="i"
               class="d-flex justify-space-between pa-3 rounded grey lighten-4 mb-2">
            <v-avatar size="40" rounded>
              <v-img :src="FILE_URL + image" class="rounded cursor-pointer"/>
            </v-avatar>
            <v-spacer/>
            <v-btn color="red" icon @click="removePhoto(i)">
              <v-icon>mdi-close-circle</v-icon>
            </v-btn>
          </div>
        </div>

      </v-col>

      <v-col cols="12" class="mb-0 pb-0">
        <v-switch v-model="campaign.is_active"
                  class="pt-0 pa-0 ma-0"
                  color="success"
                  hide-details
                  label="Active ?"></v-switch>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
  props: ['campaign'],
  data() {
    return {
      isLoading: false,
      errors: [],
      FILE_URL: process.env.VUE_APP_FILE_URL,

      startDateMenu: false,
      endDateMenu: false,
    }
  },
  methods: {
    uploadPhotos(files) {
      if (files && files.length) {
        for (let i = 0; i < files.length; i++) {
          this.isLoading = true
          this.errors = []
          let formData = new FormData()
          formData.append('image', files[i])
          HTTP.post('/v1/opus/campaigns/upload/image', formData).then((res) => {
            this.isLoading = false
            this.campaign.images.push(res.data.data)
          }).catch(err => {
            this.$errorMessage = "Une erreur ç'est produit veuillez le corriger."
            this.errors = err.response.data.errors
            this.isLoading = false
            console.log(err)
          })
        }
      }
    },
    removePhoto(i) {
      this.campaign.images.splice(i, 1)
    },
  }
}
</script>

<style scoped>

</style>
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store";
import Nprogress from 'nprogress'
import './style/nprogress.css';

import giftyRoutes from './router/giftyRoutes'

Vue.use(VueRouter)

const routes = [
    {
        path: '*',
        name: 'notFound',
        component: () => import('./views/notFound'),
        meta: {
            title: 'Page not found'
        }
    },
    {
        path: '/401',
        name: '401',
        component: () => import('./views/401'),
        meta: {
            title: '401'
        }
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('./views/auth/login'),
        meta: {
            title: 'Connexion'
        }
    },
    {
        path: '/e-payment/:token/external',
        name: 'external.payment',
        component: () => import('./views/qr_code/index.vue'),
        meta: {
            title: 'Payé avec Gifty DZ'
        }
    },
    {
        path: '/myAccount',
        name: 'myAccount',
        component: () => import('./views/account'),
        meta: {
            title: 'Mon compte'
        }
    },
    {
        path: '/database',
        name: 'database',
        component: () => import('./views/database'),
        meta: {
            title: 'Base de données',
            roles: ['admin', 'supplier', 'client', 'notification_manager']
        }
    },
    {
        path: '/database/:id',
        name: 'database.show',
        component: () => import('./views/database/show'),
        meta: {
            title: 'Base de données',
            roles: ['admin', 'supplier', 'client', 'notification_manager']
        }
    },
    {
        path: '/database/commissions/:id/:name',
        name: 'commissions.show',
        component: () => import('./views/commissions/show'),
        meta: {
            title: 'Details Commissions Utilisateur',
            roles: ['admin', 'supplier', 'client', 'notification_manager']
        }
    },

    /**
     ** Campaigns**
     **/
    {
        path: '/campaigns',
        name: 'campaigns',
        component: () => import('./views/campaigns'),
        meta: {
            title: 'Campagnes',
            roles: ['admin', 'supplier', 'client']
        }
    },
    {
        path: '/campaigns/create',
        name: 'campaigns.create',
        component: () => import('./views/campaigns/create'),
        meta: {
            title: 'Campagnes | Nouvelle campagne',
            roles: ['admin', 'supplier', 'client']
        }
    },
    {
        path: '/campaigns/:id/duplicate',
        name: 'campaigns.duplicate',
        component: () => import('./views/campaigns/create'),
        meta: {
            title: 'Campagnes | Dupliquer un campagne',
            roles: ['admin', 'supplier', 'client']
        }
    },


    /**
     ** Recoveries**
     **/

    {
        path: '/recoveries',
        name: 'recoveries',
        component: () => import('./views/recoveries'),
        meta: {
            title: 'Recouvrements',
            roles: ['admin', 'supplier', 'order_manager']
        }
    },
    {
        path: '/cib-recoveries',
        name: 'cib.recoveries',
        component: () => import('./views/recoveries/cibRecoveries'),
        meta: {
            title: 'Recouvrements CIB',
            roles: ['admin', 'supplier']
        }
    },
    {
        path: '/recoveries/recuperators',
        name: 'recuperators',
        component: () => import('./views/recoveries/recuperators'),
        meta: {
            title: 'Recouvrements - Récupérateurs',
            roles: ['admin', 'supplier', 'order_manager']
        }
    },
    {
        path: '/recoveries/dashboard',
        name: 'recoveries.dashboard',
        component: () => import('./views/recoveries/dashboard'),
        meta: {
            title: 'Recouvrements - Dashborad',
            roles: ['admin', 'order_manager']
        }
    },
    {
        path: '/recoveries/statistics',
        name: 'recoveries.statistics',
        component: () => import('./views/recoveries/statistics'),
        meta: {
            title: 'Recouvrements - Statistiques',
            roles: ['admin', 'supplier', 'order_manager']
        }
    },
    {
        path: '/recoveries/payments',
        name: 'recoveries.payments',
        component: () => import('./views/recoveries/payments'),
        meta: {
            title: 'Recouvrements - Paiements',
            roles: ['admin', 'order_manager']
        }
    },
    {
        path: '/recoveries/config',
        name: 'recoveries.config',
        component: () => import('./views/recoveries/config'),
        meta: {
            title: 'Recouvrements - Configuration',
            roles: ['admin', 'order_manager']
        }
    },
    {
        path: '/partner-vouchers',
        name: 'partner.vouchers',
        component: () => import('./views/partner_vouchers/index'),
        meta: {
            title: 'Boutiques partenaire',
            key: 'partnerVouchers',
            roles: ['store', 'supplier']
        }
    },
    {
        path: '/partner-vouchers/store/:id',
        name: 'partner.vouchers.store',
        component: () => import('./views/partner_vouchers/index'),
        meta: {
            title: 'Boutiques partenaire',
            key: 'partnerVouchers',
            roles: ['store', 'supplier']
        }
    },
    {
        path: '/partner-vouchers/store/:id/scan',
        name: 'partner.vouchers.scan',
        component: () => import('./views/partner_vouchers/scan'),
        meta: {
            title: 'Boutiques partenaire - scan',
            key: 'partnerVouchers',
            roles: ['store', 'supplier']
        }
    },
    {
        path: '/partner-vouchers/stores',
        name: 'partner.vouchers.stores',
        component: () => import('./views/partner_vouchers/stores'),
        meta: {
            title: 'Boutiques partenaire - stores',
            key: 'partnerVouchers',
            roles: ['supplier']
        },
    },
    {
        path: '/partner-vouchers/statistics',
        name: 'partner.vouchers.statistics',
        component: () => import('./views/partner_vouchers/statistics.vue'),
        meta: {
            title: 'Boutiques partenaire - statistiques',
            key: 'partnerVouchers',
            roles: ['supplier']
        },
    },
    {
        path: '/partner-vouchers/store/:id/scan-history',
        name: 'partner-vouchers.sacn-history',
        component: () => import('./views/partner_vouchers/scan-history/index'),
        meta: {
            title: 'Boutiques partenaire - Historique des scans',
            roles: ['supplier', 'store']
        }
    },
    {
        path: '/partner-vouchers/store/:id/payment-history',
        name: 'partner-vouchers.payment-history',
        component: () => import('./views/partner_vouchers/payment-history'),
        meta: {
            title: "Boutiques partenaire - Historique des paiements",
            roles: ['supplier', 'store']
        }
    },
    {
        path: '/partner-vouchers/my-stores',
        component: () => import('./views/partner_vouchers/my-stores'),
        meta: {
            title: 'Mes boutiques',
            roles: ['supplier']
        }
    },

    {
        path: '/transactions',
        name: 'transaction',
        component: () => import('./views/transaction'),
        meta: {
            title: 'Transactions',
            roles: ['admin', 'supplier', 'client']
        }
    },
    {
        path: '/services',
        name: 'serives',
        component: () => import('./views/service'),
        meta: {
            title: 'Services',
            roles: ['admin']
        }
    },

    {
        path: '/voucher-order',
        name: 'Commande Bon d\'achat',
        component: () => import('./views/voucher_orders'),
        meta: {
            title: 'Services',
            roles: ['admin', 'support', 'order_manager']
        }
    },
    {
        path: '/',
        name: 'home',
        component: () => import('./views/dashboard'),
        meta: {
            title: 'Dashboard',
            roles: ['admin']
        }
    },
    {
        path: '/analyses',
        name: 'analyses',
        component: () => import('./views/analyses'),
        meta: {
            title: 'Analyses',
            roles: ['admin']
        }
    },
    {
        path: '/partner-analyses',
        name: 'partner.analyses',
        component: () => import('./views/partner_analyses/index'),
        meta: {
            title: 'analyses partenaires',
            roles: ['admin']
        }
    },
    {
        path: '/vouchers',
        name: 'vouchers',
        component: () => import('./views/vouchers'),
        meta: {
            title: 'Vouchers',
            roles: ['admin', 'supplier', 'order_manager', 'stock_manager']
        }
    },
    {
        path: '/analyses_vouchers',
        name: 'analyses_vouchers',
        component: () => import('./views/vouchers/analyses'),
        meta: {
            title: 'Analyses Vouchers',
            roles: ['admin', 'supplier']
        }
    },

    {
        path: '/analyses_vouchers/show/:id',
        name: 'analyses_vouchers.show',
        component: () => import('./views/vouchers/analyses/show'),
        meta: {
            title: 'Analyses Vouchers',
            roles: ['admin', 'supplier']
        }
    },

    {
        path: '/analyses_vouchers/drivers',
        name: 'analyses_vouchers.drivers',
        component: () => import('./views/vouchers/analyses/drivers'),
        meta: {
            title: 'Analyses Vouchers',
            roles: ['admin', 'supplier']
        }
    },

    {
        path: '/analyses_vouchers/stock',
        name: 'analyses_vouchers.stock',
        component: () => import('./views/vouchers/stock'),
        meta: {
            title: 'Stock Vouchers',
            roles: ['admin', 'supplier', 'order_manager', 'stock_manager']
        }
    },

    {
        path: '/analyses_vouchers/show_driver/:phone',
        name: 'analyses_vouchers.show_driver',
        component: () => import('./views/vouchers/analyses/showDriver'),
        meta: {
            title: 'Analyses Vouchers',
            roles: ['admin', 'supplier']
        }
    },

    {
        path: '/services',
        name: 'services',
        component: () => import('./views/service'),
        meta: {
            title: 'Services',
            roles: ['admin']
        }
    },
    {
        path: '/services/types',
        name: 'services_types',
        component: () => import('./views/serviceTypes'),
        meta: {
            title: 'Type Services',
            roles: ['admin']
        }
    },
    {
        path: '/card_types',
        name: 'cardstypes',
        component: () => import('./views/typeCards'),
        meta: {
            title: 'Cartes de recharge',
            roles: ['admin']
        }
    },
    {
        path: '/cards',
        name: 'cards',
        component: () => import('./views/cards'),
        meta: {
            title: 'Cartes de recharge',
            roles: ['admin']
        }
    },

    {
        path: '/publicities',
        name: 'publicities',
        component: () => import('./views/publicities'),
        meta: {
            title: 'Pulblicités',
            roles: ['admin', 'supplier', 'client']
        }
    },
    {
        path: '/publicities/analyse/:id',
        name: 'publicities.analyses',
        component: () => import('./views/publicities/analyse'),
        meta: {
            title: "Analyse d'une Pulblicité",
            roles: ['admin', 'supplier', 'client']
        }
    },
    {
        path: '/support',
        name: 'support',
        component: () => import('./views/support'),
        meta: {
            title: 'Support',
            roles: ['admin', 'support', 'order_manager', 'stock_manager', 'supplier']
        }
    },
    {
        path: '/support/scan-history',
        name: 'support.scan-history',
        component: () => import('./views/support/scan-history/index'),
        meta: {
            title: 'Support - Historiques des scans',
            roles: ['admin', 'support', 'stock_manager']
        }
    },
    {
        path: '/referral-codes',
        name: 'referralCodes',
        component: () => import('./views/referralCodes/index.vue'),
        meta: {
            title: 'Codes de parrainage',
            roles: ['admin']
        }
    },
    {
        path: '/users',
        name: 'users',
        component: () => import('./views/users'),
        meta: {
            title: 'users',
            roles: ['admin', 'client', 'supplier', 'support', 'supplier_manager']
        }
    },
    {
        path: '/users-verification',
        name: 'user.verification',
        component: () => import('./views/usersVerification'),
        meta: {
            title: 'Vérification des utilisateurs',
            roles: ['admin', 'support', 'stock_manager']
        }
    },
    {
        path: '/users/settings',
        name: 'users.settings',
        component: () => import('./views/users/settings'),
        meta: {
            title: 'settings',
            roles: ['admin']
        }
    },
    {
        path: '/users/:account_id',
        name: 'users.show',
        component: () => import('./views/users/show'),
        meta: {
            title: 'users',
            roles: ['admin', 'support']
        }
    },
    {
        path: '/client',
        name: 'users.client',
        component: () => import('./views/client'),
        meta: {
            title: 'client',
            roles: ['client']
        }
    },

    {
        path: '/settings',
        name: 'settings',
        component: () => import('./views/settings'),
        meta: {
            title: 'settings',
            roles: ['admin']
        }
    },

    {
        path: '/balance',
        name: 'balance',
        component: () => import('./views/balance'),
        meta: {
            title: 'Balance',
            roles: ['admin']
        }
    },

    {
        path: '/anomalies',
        name: 'anomaly',
        component: () => import('./views/anomaly'),
        meta: {
            title: 'Anomalies',
            roles: ['admin']
        }
    },
    {
        path: '/anomaly/cards',
        name: 'anomaly.cards',
        component: () => import('./views/anomaly/cardsMenu.vue'),
        meta: {
            title: 'Anomalies martschip',
            roles: ['admin']
        }
    },
    {
        path: '/boostings',
        name: 'boostings',
        component: () => import('./views/boostings/index.vue'),
        meta: {
            title: 'Boostings',
            roles: ['booster']
        }
    },
    // {
    //     path: '/anomaly/cardsSms',
    //     name: 'anomaly',
    //     component: () => import('./views/anomaly/cardsSms'),
    //     meta: {
    //         title: 'Anomalies SMS Cartes',
    //         roles: ['admin']
    //     }
    // },

    // {
    //     path: '/anomaly/consumedCards',
    //     name: 'anomaly',
    //     component: () => import('./views/anomaly/consumedCards'),
    //     meta: {
    //         title: 'Anomalies Cartes',
    //         roles: ['admin']
    //     }
    // },


    {
        path: '/balance/history',
        name: 'anomaly.balance.history',
        component: () => import('./views/account/historyBalance'),
        meta: {
            title: 'History Balance',
            roles: ['admin']
        }
    },

    {
        path: '/balance/history/:id',
        name: 'anomaly.history.show',
        component: () => import('./views/account/showHistoryBalance'),
        meta: {
            title: 'History Balance',
            roles: ['admin']
        }
    },

    /**
     *  Fees routes **
     **/

    {
        path: '/fees',
        name: 'fees',
        component: () => import('./views/fees'),
        meta: {
            title: 'Frais de service',
            roles: ['admin']
        }
    },
    {
        path: '/fees/create',
        name: 'fees.create',
        component: () => import('./views/fees/create.vue'),
        meta: {
            title: 'Ajouter un frai de service',
            roles: ['admin']
        }
    },
    {
        path: '/fees/:id/update',
        name: 'fees.edit',
        component: () => import('./views/fees/create.vue'),
        meta: {
            title: 'Modifier un frai de service',
            roles: ['admin']
        }
    },


    /**
     *  Promotions routes **
     **/

    {
        path: '/promotions',
        name: 'promotions',
        component: () => import('./views/promotions'),
        meta: {
            title: 'Promotions',
            roles: ['admin']
        }
    },
    {
        path: '/promotions/create',
        name: 'promotions.create',
        component: () => import('./views/promotions/create.vue'),
        meta: {
            title: 'Ajouter une promotion',
            roles: ['admin']
        }
    },
    {
        path: '/promotions/:id/update',
        name: 'promotions.edit',
        component: () => import('./views/promotions/create.vue'),
        meta: {
            title: 'Modifier une promotion',
            roles: ['admin']
        }
    },


    /**
     *  cashback routes **
     **/

    {
        path: '/cashback',
        name: 'cashback',
        component: () => import('./views/cashback'),
        meta: {
            title: 'Cashback',
            roles: ['admin', 'stock_manager']
        }
    },
    {
        path: '/cashback/create',
        name: 'cashback.create',
        component: () => import('./views/cashback/create.vue'),
        meta: {
            title: 'Ajouter une cashback',
            roles: ['admin', 'stock_manager']
        }
    },
    {
        path: '/cashback/:id/update',
        name: 'cashback.edit',
        component: () => import('./views/cashback/create.vue'),
        meta: {
            title: 'Modifier une cashback',
            roles: ['admin', 'stock_manager']
        }
    },


    /**
     *  Quotas routes **
     **/

    {
        path: '/quotas',
        name: 'quotas',
        component: () => import('./views/quotas'),
        meta: {
            title: 'quotas',
            roles: ['admin']
        }
    },
    {
        path: '/quotas/create',
        name: 'quotas.create',
        component: () => import('./views/quotas/create.vue'),
        meta: {
            title: 'Ajouter un quota',
            roles: ['admin']
        }
    },
    {
        path: '/quotas/:id/update',
        name: 'quotas.edit',
        component: () => import('./views/quotas/create.vue'),
        meta: {
            title: 'Modifier un quota',
            roles: ['admin']
        }
    },
    {
        path: '/quotas-cib',
        name: 'quotas-cib',
        component: () => import('./views/quotasCIB'),
        meta: {
            title: 'quotas CIB',
            roles: ['admin']
        }
    },

    /**
     *  Promo codes routes **
     **/

    {
        path: '/promo-codes',
        name: 'promo_codes',
        component: () => import('./views/promo_codes'),
        meta: {
            title: 'Codes promo',
            roles: ['admin']
        }
    },

    {
        path: '/promo-codes/create',
        name: 'promo_codes.create',
        component: () => import('./views/promo_codes/create.vue'),
        meta: {
            title: 'Ajouter un code promo',
            roles: ['admin']
        }
    },

    {
        path: '/promo-codes/:id/update',
        name: 'promo_codes.edit',
        component: () => import('./views/promo_codes/create.vue'),
        meta: {
            title: 'Modifier un code promo',
            roles: ['admin']
        }
    },

    /***
     * E-payments **
     * **/
    {
        path: '/e-payment/:token/checkout',
        name: 'e_payment.checkout',
        component: () => import('./views/e_payment/index.vue'),
        meta: {
            title: 'E-Paiemen - Checkout'
        }
    },


    {
        path: '/e-payment/:token/message',
        name: 'e_payment.message',
        component: () => import('./views/e_payment/message.vue'),
        meta: {
            title: 'E-Paiement - Message',
        }
    },

    {
        path: '/e-payments',
        name: 'e_payments',
        component: () => import('./views/e_payments'),
        meta: {
            title: 'E-Paiements',
            roles: ['admin', 'stock_manager']
        }
    },
    {
        path: '/paying-bills',
        name: 'paying-bills',
        component: () => import('./views/paying_bills'),
        meta: {
            title: 'Paiement des factures',
            roles: ['admin', 'order_manager', 'stock_manager']
        }
    },
    {
        path: '/occasion_cards',
        name: 'occasion_cards',
        component: () => import('./views/occasion_cards'),
        meta: {
            title: 'Carte d\'occasions',
            roles: ['admin']
        }
    },

    {
        path: '/occasion_cards/categories',
        name: 'occasion_categories',
        component: () => import('./views/occasion_cards/category'),
        meta: {
            title: 'Categories Carte d\'occasions',
            roles: ['admin']
        }
    },

    {
        path: "/e-payment/terms",
        name: "e_payments.terms",
        component: () => import('./views/e_payment/Terms.vue'),
        meta: {
            title: 'Conditions de remboursement'
        }

    },
    /**END**/

    {
        path: '/forgot_password',
        name: 'forgot_password',
        component: () => import('./views/gifty/ForgotPassword.vue'),
        meta: {
            title: 'Mot de passe oublié',
        }
    },
    {
        path: "/notices",
        name: "notices",
        component: () => import('./views/notices/index.vue'),
        meta: {
            title: 'Notifications',
            roles: ['admin', 'notification_manager']
        }
    },

    {
        path: '/disable_requests',
        name: 'disable_requests',
        component: () => import('./views/users/DisableRequests.vue'),
        meta: {
            title: 'Demande de suppression comptes',
            roles: ['admin', 'stock_manager','support']
        }
    },

    {
        path: '/refill/invitations',
        name: 'refill.invitations',
        component: () => import('./views/invitations'),
        meta: {
            title: 'Invitations',
            roles: ['admin']
        }
    },

    {
        path: '/service/commissions',
        name: 'commissions',
        component: () => import('./views/commissions'),
        meta: {
            title: 'Commissions',
            roles: ['admin']
        }
    },
    {
        path: '/fees/types',
        name: 'types',
        component: () => import('./views/fees/types'),
        meta: {
            title: 'Type Frais',
            roles: ['admin']
        }
    },
    {
        path: '/supplier-manager-services',
        name: 'supplier.manager.services',
        component: () => import('./views/supplier_manager/services/index.vue'),
        meta: {
            title: 'Services',
            roles: ['supplier_manager']
        }
    },
    {
        path: '/supplier-manager-services/create',
        component: () => import('./views/supplier_manager/services/create.vue'),
        meta: {
            title: 'Ajouter un service',
            roles: ['supplier_manager']
        }
    },
    {
        path: '/supplier-manager-services/:id/update',
        component: () => import('./views/supplier_manager/services/create.vue'),
        meta: {
            title: 'Modifier un service',
            roles: ['supplier_manager']
        }
    },
    {
        path: '/m/statistics',
        component: () => import('./views/mobileStat.vue'),
        meta: {
            title: 'Statistiques',
        }
    },
    {
        path: '/delete-account',
        component: () => import('./views/deleteAccount.vue'),
        meta: {
            title: 'Supprimer mon compte GIFTY',
        }
    },
    {
        path: '/report-errors',
        component: () => import('./views/report_errors/public.vue'),
        meta: {
            title: 'signaler des erreurs',
        }
    },
    {
        path: '/errors-reported',
        component: () => import('./views/report_errors/index.vue'),
        meta: {
            title: 'Poblèmes signalées',
            roles: ['admin', 'support']
        }
    },
    {
        path: '/errors-reported/list-errors',
        component: () => import('./views/report_errors/errors/index.vue'),
        meta: {
            title: 'Listes des problèmes',
            roles: ['admin', 'support']
        }
    },
    {
        path: '/accounts',
        component: () => import('./views/accounts/index.vue'),
        meta: {
            title: 'Comptes Gifty',
            roles: ['client']
        }
    },
    {
        path: '/accounts/config/sms-templates',
        component: () => import('./views/accounts/smsTemplates/index.vue'),
        meta: {
            title: 'Comptes Gifty - Modèles des sms',
            roles: ['client']
        }
    },
    {
        path: '/accounts/config/email-templates',
        component: () => import('./views/accounts/emailTemplates/index.vue'),
        meta: {
            title: 'Comptes Gifty - Modèles des emails',
            roles: ['client']
        }
    },
    {
        path: '/accounts/config/mail-settings',
        component: () => import('./views/accounts/mailSettings/index.vue'),
        meta: {
            title: 'Comptes Gifty - Configuration des emails',
            roles: ['client']
        }
    },
    {
        path: '/flexy-offers',
        component: () => import('./views/flexy-offers/index'),
        meta: {
            title: "Les offres FLEXY",
            roles: ['admin']
        }
    },

    /***
     * OPUS
     */
    {
        path: '/opus/campaigns',
        component: () => import('./views/opus/campaigns/index.vue'),
        meta: {
            title: 'OPUS - Campagnes',
            roles: ['admin', 'client']
        }
    },
    {
        path: '/opus/campaigns/create',
        component: () => import('./views/opus/campaigns/create/index.vue'),
        meta: {
            title: 'OPUS - Ajouter une campagne',
            roles: ['admin', 'client']
        }
    },
    {
        path: '/opus/campaigns/:id/edit',
        component: () => import('./views/opus/campaigns/create/index.vue'),
        name: "edit.opusCampaign",
        meta: {
            title: 'OPUS - Modifier une campagne',
            roles: ['admin', 'client']
        }
    },
    {
        path: '/opus/backoffice',
        component: () => import('./views/opus/backoffice/index'),
        meta: {
            title: 'OPUS - Backoffice',
            roles: ['admin', 'client']
        }
    },
]

var allRoutes = []
allRoutes = allRoutes.concat(routes, giftyRoutes)

function isNewStore() {
    var user = store.getters.getUser.user;
    if (user.type === "salepoint") {
        if (user.salepoint == null) {
            return true;
        }

    }
    return false;
}

const router = new VueRouter({
    mode: 'history',
    routes: allRoutes,
    scrollBehavior: (to, from, savedPosition) => {
        if (savedPosition) {
            return savedPosition;
        } else if (to.hash) {
            return {
                selector: to.hash
            };
        } else {
            return {x: 0, y: 0};
        }
    }
})

router.beforeEach((to, from, next) => {

    /**
     * Check Is logged in**
     **/

    const publicPages = [
        '/report-errors',
        '/delete-account',
        '/login',
        '/forgot_password',
        '/e-payment/:token/checkout',// Checkout page
        '/e-payment/:token/message', // Success order page
        '/gifty/register',
        '/gifty/register-particulier',
        '/gifty/register-professionel',
        '/e-payment/terms',
        '/e-payment/:token/external',//Generate payment qr code
        '/m/statistics'
    ];

    const no_edirect = [
        '/e-payments/message',
    ];

    const authRequired = !publicPages.includes(to.path);
    const loggedIn = store.state.isLoggedIn;

    if (authRequired && !loggedIn && !to.path.startsWith("/e-payment/")) {
        next('/login');
    } else if (!authRequired && loggedIn && !no_edirect.includes(to.path) && !to.path.startsWith("/e-payment/")) {
        next(store.state.home);
    } else {
        if (loggedIn) {
            store.dispatch("fetchAccount");
        }
        if (to.path === "/" && store.state.home !== "/") {
            if (isNewStore()) {
                next('/gifty/salepoint');
            } else {
                next(store.state.home);
            }
        } else {
            next()
        }
    }

    /**
     * Page title**
     **/
    document.title = process.env.VUE_APP_NAME + '::' + to.meta.title

    /**
     * Start Top progress**
     **/
    if (to.name) {
        Nprogress.start()
    }
});


/**
 * roles**
 **/
router.beforeEach((to, from, next) => {
    if (to.meta.roles) {
        const roles = to.meta.roles
        const roleName = store.state.user.roleName
        const state = !!roles.includes(roleName)
        if (state) {
            next();
        } else {
            next('/401');
        }
    } else {
        next()
    }
})


/**
 *End Top progress**
 **/
router.afterEach(() => {
    setTimeout(function () {
        Nprogress.done()
    }, 500)
})

export default router
